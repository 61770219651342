import plugin_vue3_A0OWXRrUgq from "/home/runner/work/kiausiniukaina-nuxt/kiausiniukaina-nuxt/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/kiausiniukaina-nuxt/kiausiniukaina-nuxt/.nuxt/components.plugin.mjs";
import vueuse_head_plugin_D7WGfuP1A0 from "/home/runner/work/kiausiniukaina-nuxt/kiausiniukaina-nuxt/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import router_Pg0DINazwm from "/home/runner/work/kiausiniukaina-nuxt/kiausiniukaina-nuxt/node_modules/nuxt/dist/pages/runtime/plugins/router.mjs";
import prefetch_client_3cwHvxIDEX from "/home/runner/work/kiausiniukaina-nuxt/kiausiniukaina-nuxt/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.mjs";
import plugin_OcT8LIvbNC from "/home/runner/work/kiausiniukaina-nuxt/kiausiniukaina-nuxt/node_modules/nuxt-bugsnag/dist/runtime/plugin.mjs";
import payload_client_5Om5dvb8Jc from "/home/runner/work/kiausiniukaina-nuxt/kiausiniukaina-nuxt/node_modules/nuxt/dist/app/plugins/payload.client.mjs";
import floating_vue_Grexwy95Ly from "/home/runner/work/kiausiniukaina-nuxt/kiausiniukaina-nuxt/plugins/floating-vue.js";
import lazyload_7c1dvPh0fH from "/home/runner/work/kiausiniukaina-nuxt/kiausiniukaina-nuxt/plugins/lazyload.js";
import persistedstate_client_gKEOIaWWdH from "/home/runner/work/kiausiniukaina-nuxt/kiausiniukaina-nuxt/plugins/persistedstate.client.js";
export default [
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  vueuse_head_plugin_D7WGfuP1A0,
  router_Pg0DINazwm,
  prefetch_client_3cwHvxIDEX,
  plugin_OcT8LIvbNC,
  payload_client_5Om5dvb8Jc,
  floating_vue_Grexwy95Ly,
  lazyload_7c1dvPh0fH,
  persistedstate_client_gKEOIaWWdH
]