import { default as claims8oz1Sj1YnsMeta } from "/home/runner/work/kiausiniukaina-nuxt/kiausiniukaina-nuxt/pages/claims.vue?macro=true";
import { default as companies1ZfNcEFVqpMeta } from "/home/runner/work/kiausiniukaina-nuxt/kiausiniukaina-nuxt/pages/companies.vue?macro=true";
import { default as contactsZLwGhQOALsMeta } from "/home/runner/work/kiausiniukaina-nuxt/kiausiniukaina-nuxt/pages/contacts.vue?macro=true";
import { default as donateNXGM8NClehMeta } from "/home/runner/work/kiausiniukaina-nuxt/kiausiniukaina-nuxt/pages/donate.vue?macro=true";
import { default as feedback_45form40Ega6YrnwMeta } from "/home/runner/work/kiausiniukaina-nuxt/kiausiniukaina-nuxt/pages/feedback-form.vue?macro=true";
import { default as index3snbaqZBgMMeta } from "/home/runner/work/kiausiniukaina-nuxt/kiausiniukaina-nuxt/pages/index.vue?macro=true";
import { default as opiniondGrQIRPZo4Meta } from "/home/runner/work/kiausiniukaina-nuxt/kiausiniukaina-nuxt/pages/opinion.vue?macro=true";
import { default as returnHkFYPkGufNMeta } from "/home/runner/work/kiausiniukaina-nuxt/kiausiniukaina-nuxt/pages/return.vue?macro=true";
import { default as shareqQcSnAcuqPMeta } from "/home/runner/work/kiausiniukaina-nuxt/kiausiniukaina-nuxt/pages/share.vue?macro=true";
import { default as should_45confirm_45emailVfKxeYPX6yMeta } from "/home/runner/work/kiausiniukaina-nuxt/kiausiniukaina-nuxt/pages/should-confirm-email.vue?macro=true";
import { default as thankswbIuNNe8WCMeta } from "/home/runner/work/kiausiniukaina-nuxt/kiausiniukaina-nuxt/pages/thanks.vue?macro=true";
import { default as unsubscribedQItxDpWpiTMeta } from "/home/runner/work/kiausiniukaina-nuxt/kiausiniukaina-nuxt/pages/unsubscribed.vue?macro=true";
import { default as writeqKq1Qw5fHBMeta } from "/home/runner/work/kiausiniukaina-nuxt/kiausiniukaina-nuxt/pages/write.vue?macro=true";
export default [
  {
    name: claims8oz1Sj1YnsMeta?.name ?? "claims",
    path: claims8oz1Sj1YnsMeta?.path ?? "/claims",
    children: [],
    meta: claims8oz1Sj1YnsMeta || {},
    alias: claims8oz1Sj1YnsMeta?.alias || [],
    redirect: claims8oz1Sj1YnsMeta?.redirect || undefined,
    component: () => import("/home/runner/work/kiausiniukaina-nuxt/kiausiniukaina-nuxt/pages/claims.vue").then(m => m.default || m)
  },
  {
    name: companies1ZfNcEFVqpMeta?.name ?? "companies",
    path: companies1ZfNcEFVqpMeta?.path ?? "/companies",
    children: [],
    meta: companies1ZfNcEFVqpMeta || {},
    alias: companies1ZfNcEFVqpMeta?.alias || [],
    redirect: companies1ZfNcEFVqpMeta?.redirect || undefined,
    component: () => import("/home/runner/work/kiausiniukaina-nuxt/kiausiniukaina-nuxt/pages/companies.vue").then(m => m.default || m)
  },
  {
    name: contactsZLwGhQOALsMeta?.name ?? "contacts",
    path: contactsZLwGhQOALsMeta?.path ?? "/contacts",
    children: [],
    meta: contactsZLwGhQOALsMeta || {},
    alias: contactsZLwGhQOALsMeta?.alias || [],
    redirect: contactsZLwGhQOALsMeta?.redirect || undefined,
    component: () => import("/home/runner/work/kiausiniukaina-nuxt/kiausiniukaina-nuxt/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: donateNXGM8NClehMeta?.name ?? "donate",
    path: donateNXGM8NClehMeta?.path ?? "/donate",
    children: [],
    meta: donateNXGM8NClehMeta || {},
    alias: donateNXGM8NClehMeta?.alias || [],
    redirect: donateNXGM8NClehMeta?.redirect || undefined,
    component: () => import("/home/runner/work/kiausiniukaina-nuxt/kiausiniukaina-nuxt/pages/donate.vue").then(m => m.default || m)
  },
  {
    name: feedback_45form40Ega6YrnwMeta?.name ?? "feedback-form",
    path: feedback_45form40Ega6YrnwMeta?.path ?? "/feedback-form",
    children: [],
    meta: feedback_45form40Ega6YrnwMeta || {},
    alias: feedback_45form40Ega6YrnwMeta?.alias || [],
    redirect: feedback_45form40Ega6YrnwMeta?.redirect || undefined,
    component: () => import("/home/runner/work/kiausiniukaina-nuxt/kiausiniukaina-nuxt/pages/feedback-form.vue").then(m => m.default || m)
  },
  {
    name: index3snbaqZBgMMeta?.name ?? "index",
    path: index3snbaqZBgMMeta?.path ?? "/",
    children: [],
    meta: index3snbaqZBgMMeta || {},
    alias: index3snbaqZBgMMeta?.alias || [],
    redirect: index3snbaqZBgMMeta?.redirect || undefined,
    component: () => import("/home/runner/work/kiausiniukaina-nuxt/kiausiniukaina-nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: opiniondGrQIRPZo4Meta?.name ?? "opinion",
    path: opiniondGrQIRPZo4Meta?.path ?? "/opinion",
    children: [],
    meta: opiniondGrQIRPZo4Meta || {},
    alias: opiniondGrQIRPZo4Meta?.alias || [],
    redirect: opiniondGrQIRPZo4Meta?.redirect || undefined,
    component: () => import("/home/runner/work/kiausiniukaina-nuxt/kiausiniukaina-nuxt/pages/opinion.vue").then(m => m.default || m)
  },
  {
    name: returnHkFYPkGufNMeta?.name ?? "return",
    path: returnHkFYPkGufNMeta?.path ?? "/return",
    children: [],
    meta: returnHkFYPkGufNMeta || {},
    alias: returnHkFYPkGufNMeta?.alias || [],
    redirect: returnHkFYPkGufNMeta?.redirect || undefined,
    component: () => import("/home/runner/work/kiausiniukaina-nuxt/kiausiniukaina-nuxt/pages/return.vue").then(m => m.default || m)
  },
  {
    name: shareqQcSnAcuqPMeta?.name ?? "share",
    path: shareqQcSnAcuqPMeta?.path ?? "/share",
    children: [],
    meta: shareqQcSnAcuqPMeta || {},
    alias: shareqQcSnAcuqPMeta?.alias || [],
    redirect: shareqQcSnAcuqPMeta?.redirect || undefined,
    component: () => import("/home/runner/work/kiausiniukaina-nuxt/kiausiniukaina-nuxt/pages/share.vue").then(m => m.default || m)
  },
  {
    name: should_45confirm_45emailVfKxeYPX6yMeta?.name ?? "should-confirm-email",
    path: should_45confirm_45emailVfKxeYPX6yMeta?.path ?? "/should-confirm-email",
    children: [],
    meta: should_45confirm_45emailVfKxeYPX6yMeta || {},
    alias: should_45confirm_45emailVfKxeYPX6yMeta?.alias || [],
    redirect: should_45confirm_45emailVfKxeYPX6yMeta?.redirect || undefined,
    component: () => import("/home/runner/work/kiausiniukaina-nuxt/kiausiniukaina-nuxt/pages/should-confirm-email.vue").then(m => m.default || m)
  },
  {
    name: thankswbIuNNe8WCMeta?.name ?? "thanks",
    path: thankswbIuNNe8WCMeta?.path ?? "/thanks",
    children: [],
    meta: thankswbIuNNe8WCMeta || {},
    alias: thankswbIuNNe8WCMeta?.alias || [],
    redirect: thankswbIuNNe8WCMeta?.redirect || undefined,
    component: () => import("/home/runner/work/kiausiniukaina-nuxt/kiausiniukaina-nuxt/pages/thanks.vue").then(m => m.default || m)
  },
  {
    name: unsubscribedQItxDpWpiTMeta?.name ?? "unsubscribed",
    path: unsubscribedQItxDpWpiTMeta?.path ?? "/unsubscribed",
    children: [],
    meta: unsubscribedQItxDpWpiTMeta || {},
    alias: unsubscribedQItxDpWpiTMeta?.alias || [],
    redirect: unsubscribedQItxDpWpiTMeta?.redirect || undefined,
    component: () => import("/home/runner/work/kiausiniukaina-nuxt/kiausiniukaina-nuxt/pages/unsubscribed.vue").then(m => m.default || m)
  },
  {
    name: writeqKq1Qw5fHBMeta?.name ?? "write",
    path: writeqKq1Qw5fHBMeta?.path ?? "/write",
    children: [],
    meta: writeqKq1Qw5fHBMeta || {},
    alias: writeqKq1Qw5fHBMeta?.alias || [],
    redirect: writeqKq1Qw5fHBMeta?.redirect || undefined,
    component: () => import("/home/runner/work/kiausiniukaina-nuxt/kiausiniukaina-nuxt/pages/write.vue").then(m => m.default || m)
  }
]